@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'SpaceGrotesk';
  src: url('../assets/fonts/SpaceGrotesk-Regular.ttf') format('truetype');
  font-weight: 400; /* Adjust based on actual weight */
  font-style: normal;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('../assets/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype');
  font-weight: 600; /* Adjust based on actual weight */
  font-style: normal;
}

body {
  font-family: 'SpaceGrotesk', sans-serif;
}
 

.ant-select-focused .ant-select-selector,
.ant-select-selector:hover,
.ant-select-open .ant-select-selector {
  border-color: #4ade80 !important;
  box-shadow: 0 0 0 2px rgba(74, 222, 128, 0.2) !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border-color: #4ade80 !important;
  box-shadow: 0 0 0 2px rgba(74, 222, 128, 0.2) !important;
}

.ant-btn-primary {
  background-color: #4ade80 !important;
  border-color: #4ade80 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #22c55e !important;
  border-color: #22c55e !important;
}

.ant-dropdown-menu-item:hover {
  background-color: rgba(74, 222, 128, 0.1) !important;
}

.ant-dropdown-menu-item-selected {
  background-color: rgba(74, 222, 128, 0.2) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(74, 222, 128, 0.2) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(74, 222, 128, 0.1) !important;
}